import { Component, Vue, Watch, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel } from '@/models/Report';
import { ReportLog } from '@/models/ReportLog';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { getStatusColor, getStatusLabel, getStyledStatusLog } from '@/support/ReportStatus';
import { debounce } from 'lodash';

@Component<Communicatie>({})
export default class Communicatie extends Vue {
  public $pageTitle = 'Communicatie';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  @Model('allowEdit', { default: false })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isLoadingMoreMessage = false;

  protected debouncedScroll: Function = debounce(this.scrollLogContainer, 500);

  // Create Message
  protected panel: any = [];

  protected panelVisible = false;

  protected comment = '';

  protected isInternal = true;

  // List ReportLogs
  protected reportLogs: ReportLog[] = [];

  protected page = 1;

  protected pageSize = 0;

  protected selectedLogTypes: string[] = [];

  protected logTypes: LogType[] = [
    {
      label: 'Status verandering',
      value: 'status_change',
    },
    {
      label: 'Bericht',
      value: 'message',
    },
    {
      label: 'Logbericht',
      value: 'change',
    },
    {
      label: 'BI update',
      value: 'bi_update',
    },
    {
      label: 'Synchronisatiebericht',
      value: 'sync',
    },
    {
      label: 'Afkeurredenen',
      value: 'reject_reason',
    },
    {
      label: 'Deskundige wijzigingen',
      value: 'expert_change',
    },
  ];

  @Watch('selectedLogTypes', { deep: true })
  protected selectedLogTypesChanged() {
    this.reportLogs = [];
    this.page = 1;
    this.fetchReportLogs();
  }

  // Helpers:
  protected planningChangeRegex = /(.*):\n[-]+[\n]{2}(.|\n)*/g;

  public async mounted() {
    this.isLoading = true;
    await this.fetchReportLogs();

    this.isLoading = false;
    this.isLoadingMoreMessage = false;
    window.addEventListener('scroll', this.scrollEvent);
  }

  protected async fetchReportLogs() {
    const filter: ReportLogFilter = {
      report: this.$route.params.id,
    };

    if (this.selectedLogTypes && this.selectedLogTypes.length) {
      filter.types = this.selectedLogTypes;
    }

    await new ReportLog()
      .filter(filter)
      .limit(20)
      .page(this.page)
      .sort('created_at', 'DESC')
      .all()
      .then((reportLogs: ReportLog[]) => {
        this.reportLogs = [...this.reportLogs, ...reportLogs];
        if (reportLogs[0]) {
          this.pageSize = (reportLogs[0].meta as any).last_page;
        }

        this.isLoading = false;
        this.isLoadingMoreMessage = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected scrollEvent() {
    this.debouncedScroll();
  }

  protected scrollLogContainer() {
    if (! this.$refs.logContainer) {
      return;
    }

    const container: any = this.$refs.logContainer;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = container.getBoundingClientRect();
    const offset = elemRect.bottom - bodyRect.bottom;

    if (scrollPosition > Math.floor(offset) - 10) {
      if (this.page === 1 && this.pageSize === 1) return;
      if (this.page >= this.pageSize) return;
      this.page ++;
      this.fetchReportLogs();
    }
  }

  protected sendMessage(comment: string) {
    const payload = {
      report: this.$route.params.id,
      text: comment,
      is_internal: this.isInternal,
    };

    new ReportLog()
      .create(payload)
      .then((response: AxiosResponse) => {
        this.comment = '';
        this.isLoading = true;
        this.reportLogs = [];
        this.page = 1;
        this.fetchReportLogs();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected togglePanel() {
    this.panelVisible = ! this.panelVisible;
    this.panel = [this.panelVisible];
  }

  // Helpers:
  protected getStatusColor(key: string) {
    return getStatusColor(key);
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  protected getStyledStatusLog(reportLog: ReportLogFilter) {
    if (reportLog && reportLog.text) {
      return getStyledStatusLog(reportLog.text, reportLog.type);
    }

    return '';
  }

  protected getReportLogAction(reportLog: ReportLog) {
    if (! reportLog.text) {
      return '';
    }

    return reportLog.text.replace(this.planningChangeRegex, '');
  }

  protected getReportLogMessage(reportLog: ReportLog) {
    if (! reportLog.text) {
      return '';
    }

    return (reportLog.text.match(this.planningChangeRegex) || '').toString();
  }
}

interface LogType {
  label: string;
  value: string;
}

export interface ReportLogFilter {
  report: string;
  type?: string;
  types?: string[];
  text?: string;
}
