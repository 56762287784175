var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel" },
    [
      [
        _c(
          "v-layout",
          { attrs: { row: "", wrap: "" } },
          [
            _c(
              "v-flex",
              { staticClass: "report-comment", attrs: { sm12: "" } },
              [
                _c(
                  "v-expansion-panel",
                  {
                    staticClass: "commentPanel",
                    attrs: { expand: "", readonly: "" },
                    model: {
                      value: _vm.panel,
                      callback: function($$v) {
                        _vm.panel = $$v
                      },
                      expression: "panel"
                    }
                  },
                  [
                    _c(
                      "v-expansion-panel-content",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "tab__header" },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: { wrap: "", "align-center": "" }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: { sm6: "", md6: "", lg8: "" }
                                          },
                                          [
                                            _vm.panelVisible
                                              ? _c("h3", [
                                                  _vm._v("Bericht plaatsen")
                                                ])
                                              : _vm._e(),
                                            !_vm.panelVisible
                                              ? _c("v-select", {
                                                  staticClass: "logTypesSelect",
                                                  attrs: {
                                                    items: _vm.logTypes,
                                                    "item-value": "value",
                                                    "item-text": "label",
                                                    label:
                                                      "Filter communicatie berichten",
                                                    multiple: "",
                                                    clearable: ""
                                                  },
                                                  model: {
                                                    value: _vm.selectedLogTypes,
                                                    callback: function($$v) {
                                                      _vm.selectedLogTypes = $$v
                                                    },
                                                    expression:
                                                      "selectedLogTypes"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "text-xs-right",
                                            attrs: { sm6: "", md6: "", lg4: "" }
                                          },
                                          [
                                            _vm.allowEdit && !_vm.syncing
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "handlepanel"
                                                  },
                                                  [
                                                    _c("v-flex", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "panel"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.togglePanel()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.panelVisible
                                                                      ? "Annuleer"
                                                                      : "Plaats"
                                                                  ) +
                                                                  " bericht "
                                                              ),
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.panelVisible
                                                                      ? "keyboard_arrow_up"
                                                                      : "keyboard_arrow_down"
                                                                  )
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab__body" },
                          [
                            _c("v-textarea", {
                              attrs: { label: "Voeg hier uw opmerking toe..." },
                              model: {
                                value: _vm.comment,
                                callback: function($$v) {
                                  _vm.comment = $$v
                                },
                                expression: "comment"
                              }
                            }),
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                label: "Plaats opmerking intern"
                              },
                              model: {
                                value: _vm.isInternal,
                                callback: function($$v) {
                                  _vm.isInternal = $$v
                                },
                                expression: "isInternal"
                              }
                            }),
                            _c(
                              "MiButton",
                              {
                                attrs: { color: "primary", icon: "send" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.sendMessage(_vm.comment)
                                  }
                                }
                              },
                              [_vm._v("Bericht versturen")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "300px"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !_vm.isLoading
              ? _c(
                  "v-flex",
                  {
                    ref: "logContainer",
                    staticClass: "logContainer report-messages",
                    attrs: { sm12: "" }
                  },
                  _vm._l(_vm.reportLogs, function(reportLog, index) {
                    return _c(
                      "div",
                      {
                        key: "default-" + reportLog.id + index,
                        staticClass: "report-message"
                      },
                      [
                        reportLog.isMessage || reportLog.isRejectReason
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "report-message__block comment"
                                },
                                [
                                  _c("div", { staticClass: "message__meta" }, [
                                    _c(
                                      "span",
                                      { staticClass: "message__created" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFormatTime")(
                                                reportLog.created_at
                                              )
                                            ) +
                                            " door "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tw-font-bold tw-pl-4"
                                          },
                                          [_vm._v(_vm._s(reportLog.user))]
                                        ),
                                        reportLog.is_internal
                                          ? _c(
                                              "span",
                                              { staticClass: "intern" },
                                              [_vm._v("intern")]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]),
                                  _c("pre", {
                                    staticClass: "message__text",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getStyledStatusLog(reportLog)
                                      )
                                    }
                                  })
                                ]
                              )
                            ])
                          : reportLog.isPlanningStatusChange
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  class: _vm.getReportLogMessage(reportLog)
                                    .length
                                    ? ""
                                    : "report-message__block"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mt-2 message__meta" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "message__created" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("dateFormatTime")(
                                                  reportLog.created_at
                                                )
                                              ) +
                                              " door "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-font-bold tw-pl-4"
                                            },
                                            [_vm._v(_vm._s(reportLog.user))]
                                          ),
                                          reportLog.is_internal
                                            ? _c(
                                                "span",
                                                { staticClass: "intern" },
                                                [_vm._v("intern")]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("pre", { staticClass: "message__text" }, [
                                    _vm._v(
                                      _vm._s(_vm.getReportLogAction(reportLog))
                                    )
                                  ]),
                                  _vm.getReportLogMessage(reportLog).length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "report-message__block comment"
                                        },
                                        [
                                          _c(
                                            "pre",
                                            { staticClass: "message__text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getReportLogMessage(
                                                    reportLog
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "report-message__block status-change"
                              },
                              [
                                _c("div", { staticClass: "message__meta" }, [
                                  _c("span", {
                                    staticClass: "message__circle",
                                    class:
                                      "statusBackgroundColor--" +
                                      _vm.getStatusColor(reportLog.changed_to)
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "message__created" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormatTime")(
                                              reportLog.created_at
                                            )
                                          ) +
                                          " "
                                      ),
                                      reportLog.user
                                        ? _c("span", [_vm._v("door")])
                                        : _vm._e(),
                                      reportLog.user
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-font-bold tw-pl-4"
                                            },
                                            [_vm._v(_vm._s(reportLog.user))]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]),
                                _c("div", {
                                  staticClass: "message__text",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.getStyledStatusLog(reportLog)
                                    )
                                  }
                                })
                              ]
                            )
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("v-flex", { attrs: { sm12: "" } }, [
              _c(
                "div",
                { staticClass: "logsLoadingContainer" },
                [
                  _vm.isLoadingMoreMessage
                    ? _c("LoaderCard", {
                        staticClass: "logsLoadingContainer__loader",
                        attrs: { flat: "", type: "spinner--center" }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }